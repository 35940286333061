import { Typography } from '@mui/material';
import classnames from 'classnames';
import { withStyles } from 'src/styles/utils';
import { toFixed } from 'src/utilities';

import { styles } from './ItemsCounterIcon.styles';
import { ItemsCounterIconProps } from './ItemsCounterIcon.types';

const ItemsCounterIconBase: React.FC<ItemsCounterIconProps> = (
    props: Readonly<ItemsCounterIconProps>,
) => {
    const { count, maxNumberOfItems, classes, showEmptyEllipseIfExceeds, keepCounterMounted } =
        props;

    const countIsLTEZero = count <= 0;

    let counterNode: React.ReactNode;
    if (countIsLTEZero && !keepCounterMounted) {
        counterNode = null;
    } else {
        let counterDisplayValueNode: React.ReactNode;
        if (countIsLTEZero) {
            counterDisplayValueNode = null;
        } else if (count <= maxNumberOfItems) {
            counterDisplayValueNode = toFixed(count, 0);
        } else if (showEmptyEllipseIfExceeds) {
            counterDisplayValueNode = <span>&nbsp;</span>;
        } else {
            counterDisplayValueNode = `${toFixed(maxNumberOfItems, 0)}+`;
        }
        counterNode = (
            <Typography
                className={classnames(classes.counter, {
                    [classes.counterMount]: !countIsLTEZero,
                })}
                automation-id="items-counter-cart-count"
            >
                {counterDisplayValueNode}
            </Typography>
        );
    }

    return (
        <>
            {props.iconNode}
            {counterNode}
        </>
    );
};

export { ItemsCounterIconBase };

const WithStyles = withStyles(styles)(ItemsCounterIconBase);

export default WithStyles;
